import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ModalEnum, TypeSocialEnum } from '@enums';
import { environment } from '@env';
import { IAccountProfile, IToken } from '@interfaces';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { AuthService, ModalsService, ProfileService } from '@service';
import * as HeaderActions from '@store';
import { SocialAuthService, SocialUser } from '@abacritt/angularx-social-login';
import { ToastrService } from 'ngx-toastr';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { generateHash } from '@helpers';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss'],
})
export class SignInComponent implements OnInit {
  public form: FormGroup;
  public activeTab: 'login' | 'register' | 'forgot' = 'login';
  public error: string = '';

  protected readonly typeSocialEnum = TypeSocialEnum;
  private destroyRef = inject(DestroyRef);

  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private modalsService: ModalsService,
    private store: Store,
    private translate: TranslateService,
    private socialAuthService: SocialAuthService,
    private toastrService: ToastrService,
    private profileService: ProfileService,
  ) {}

  ngOnInit(): void {
    this.createForm();

    this.socialAuthService.authState.subscribe((user: SocialUser) => {
      if (!user) {
        this.toastrService.error(this.translate.instant('signin.social_login_error'));
      }

      switch (user.provider) {
        case 'GOOGLE':
          console.log('>>> google auth response : ', user);
          // TODO: add google sign in to our API
          break;
      }
    });
  }

  public setTabValue(value: 'login' | 'register' | 'forgot') {
    this.activeTab = value;
    this.form.reset();
  }

  public register() {
    this.authService
      .signUp({ ...this.form.value, hash: generateHash(this.form.value.email) })
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(
        (res: IToken) => {
          this.closeModal();
          this.authService.saveToken(res);
          this.store.dispatch(HeaderActions.updateLoggedState({ data: true }));
        },
        (error) => {
          this.setAuthError(error.error.message ?? error.error.error);
        },
      );
  }

  public signEmail() {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }

    this.authService
      .signIn({ ...this.form.value, hash: generateHash(this.form.value.email) })
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(
        (res: IToken) => {
          this.store.dispatch(HeaderActions.updateLoggedState({ data: true }));
          this.closeModal();
          this.authService.saveToken(res);
          // this.store.dispatch(HeaderActions.refreshToken());
          this.setLanguage();
        },
        (error) => {
          this.setAuthError(error.error.message ?? error.error.error);
        },
      );
  }

  public closeModal() {
    this.modalsService.close(ModalEnum.AUTH);
  }

  private createForm() {
    this.form = this.formBuilder.group({
      email: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', [Validators.required, Validators.minLength(8)]),
    });
  }

  private setAuthError(error: string) {
    this.error = this.translate.instant(error);
    setTimeout(() => (this.error = ''), 3000);
  }

  private setLanguage(): void {
    this.profileService
      .getProfile()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((res: IAccountProfile) => {
        // TODO: uncomment when language will be implemented on API
        // const usedLng = localStorage.getItem(LocalStorageEnum.CURRENT_LANGUAGE);
        // if (!usedLng?.length || (res.language?.length && usedLng !== res.language)) {
        //   localStorage.setItem(LocalStorageEnum.CURRENT_LANGUAGE, res.language);
        //   this.translate.use(res.language);
        //   window.location.reload();
        // }
      });
  }

  protected readonly environment = environment;
}
