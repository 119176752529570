export class Header {
  public headerMenu = [];
  private isAddedUserMenu = false;

  constructor() {
    this.headerMenu = [
      this.createMenuItem('header.buy', '2', [
        this.createChildItem('header.buy_homes_for_sale', [
          this.createLink('header.buy_houses', '2', '2'),
          this.createLink('header.buy_condos', '2', '1'),
          this.createLink('header.buy_townhomes', '2', '5'),
          this.createLink('header.buy_multi_family', '2', '6'),
          this.createLink('header.buy_holiday_houses', '2', '4'),
        ]),
        this.createChildItem('header.buy_objects_for_sale', [
          this.createLink('header.buy_land', '2', '7'),
          this.createLink('header.buy_commercial', '2', '3'),
          this.createLink('header.buy_manufactured', '2', '8'),
          this.createLink('header.buy_objects', '2', '9'),
        ]),
      ]),
      this.createMenuItem('header.rent', '3', [
        this.createChildItem('header.rentals', [
          this.createLink('header.rent_houses', '3', '2'),
          this.createLink('header.rent_condos', '3', '1'),
          this.createLink('header.rent_townhomes', '3', '5'),
          this.createLink('header.rent_multi_family', '3', '6'),
          this.createLink('header.rent_holiday_houses', '3', '4'),
        ]),
        this.createChildItem('header.renter_nub', [
          this.createLink('header.rent_land', '3', '7'),
          this.createLink('header.rent_commercial', '3', '3'),
          this.createLink('header.rent_manufactured', '3', '8'),
          this.createLink('header.rent_objects', '3', '9'),
        ]),
      ]),
    ];
  }

  public menu() {
    return this.headerMenu;
  }

  public addUserMenu() {
    if (this.isAddedUserMenu) {
      return;
    }

    const userMenu = this.createMenuItem(
      'header.my_cabinet',
      null,
      [
        this.createChildItem('header.properties', [
          this.createUserLink('cabinet_tabs.your_homes', 'your-homes'),
          this.createUserLink('cabinet_tabs.saved_homes', 'saved-homes'),
          // this.createUserLink('cabinet_tabs.import_properties', 'import-properties'),
        ]),
        this.createChildItem('header.settings', [
          this.createUserLink('cabinet_tabs.account_settings', 'account-settings/profile'),
        ]),
      ],
      '/myzex/your-homes',
    );

    this.headerMenu.push(userMenu);
    return this.headerMenu;
  }

  public removeUserMenu() {
    if (this.headerMenu.length === 2) {
      return this.headerMenu;
    }

    this.headerMenu.pop();
    this.isAddedUserMenu = false;

    return this.headerMenu;
  }

  private createMenuItem(name: string, propertyType: string, children: any[], path?: string) {
    return {
      name,
      path: path ?? '/search',
      params: { propertyType },
      child: children,
    };
  }

  private createChildItem(title: string, links: any[]) {
    return {
      title,
      links: [links],
    };
  }

  private createLink(name: string, propertyType: string, homeType: string) {
    return {
      name,
      path: '/search',
      params: { propertyType, homeType },
    };
  }

  private createUserLink(name: string, path: string) {
    return {
      name,
      path: `/myzex/${path}`,
    };
  }
}
